<template>
  <div class="navigation-wrapper">
    <template v-for="step in steps">
      <component
        :is="!step.children ? 'router-link' : 'div'"
        v-if="step.visibleDesktop"
        :key="step.route"
        :to="{name: step.route}"
        class="d-flex navigation-step decoration-none"
        :class="{ active: $route.name.includes(step.route) }"
        @click="handleStepClick(step)"
        @mouseenter="toggleStep(step.route)"
        @mouseleave="awaySettings"
      >
        <div class="d-flex align-items-center mx-3 mx-md-auto mx-xl-3 flex-md-column flex-xl-row text-center justify-content-md-center justify-content-xl-start">
          <!--          <i-->
          <!--            v-if="step.icon"-->
          <!--            class="ib-icon navigation-step-icon h2"-->
          <!--            :class="step.icon"-->
          <!--          />-->
          <p>
            {{ $t(step.name) }}
            <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" v-if="step.children" v-bind:class="'caret-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
          </p>
        </div>

        <transition name="fade">
          <div
            v-if="step.children && openStep === step.route"
            v-on-clickaway="awaySettings"
            class="navigation-step-dropdown"
          >
            <component
              :is="subscriptionIsFreeTrial && child.paid ? 'span' : 'router-link'"
              v-for="child in step.children"
              :key="child.route"
              :to="{name: child.route}"
              class="navigation-step-item d-flex align-items-center mx-3 decoration-none"
              :class="{ active: $route.name.includes(child.route) }"
              @click="onChildClick(child)"
            >
              <i
                v-if="child.icon"
                class="ib-icon navigation-step-item-icon h2"
                :class="child.icon"
              />
              <span>{{ $t(child.name) }}
                <template v-if="subscriptionIsFreeTrial && child.paid">
                  <i class="ib-icon ib-icon-lock-2 o-50" />
                </template>
              </span>
            </component>
          </div>
        </transition>
      </component>
    </template>
  </div>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import { directive as onClickaway } from 'vue-clickaway'

import { mapMutations } from 'vuex'

export default {
  name: 'IbMainNavigation',

  directives: {
    onClickaway: onClickaway
  },

  mixins: [MixinUserSubscription],

  props: {
    steps: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      openStep: null,
      timeout: null
    }
  },

  watch: {
    '$route.name' () {
      this.closeStep()
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogUpgradeState']),

    handleStepClick (step) {
      if (this.$isTouchDevice) {
        this.openStep = step.route
      }
    },

    onChildClick (child) {
      if (this.subscriptionIsFreeTrial && child.paid) {
        this.setDialogUpgradeState(true)
      }
    },

    toggleStep (route) {
      if (!this.$isTouchDevice) {
        clearTimeout(this.timeout)
        this.openStep = route
      }
    },

    awaySettings () {
      this.timeout = setTimeout(() => {
        this.closeStep()
      }, 100)
    },

    closeStep () {
      this.openStep = null
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border-radius: 4px;
  background-color: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.navigation-step {
  position: relative;
  width: 100%;
  border-left: 1px solid #ececec;
  cursor: pointer;
  border-bottom: 3px solid $color-white;
  transition: all 0.25s;
  padding: 14px 0;

  @include media-breakpoint-between($md, $xl) {
    display: flex;
  }

  &:hover {
    svg {
      fill: #0060ee;
      visibility: visible;
    }
  }

  &.active {
    svg {
      fill: #0060ee;
      visibility: visible;
    }
  }

  .navigation-step-icon {
    color: $color-text-gray;
  }

  .caret-icon {
    transition: all 0.25s;
    display: inline-block;
    width: 11px;
    height: auto;
    margin-left: 15px;
    fill: gray;
    visibility: hidden;

    @include media-breakpoint-between($md, $xl) {
      margin-left: 5px;
    }
  }

  &:hover .navigation-step-icon,
  &.active .navigation-step-icon,
  &:hover p,
  &.active p {
    transition: all 0.25s;
    color: $color-primary;
    font-weight: 700;
  }

  & p {
    transition: all 0.25s;
    font-size: 17px;
    color: $color-text-gray;
    margin: 0;
  }

  & i {
    transition: all 0.25s;
  }

  &:first-child {
    border-left: 1px solid transparent;
  }

  &:hover {
    border-bottom: 3px solid silver;
    transition: all 0.25s;
  }

  &.active {
    border-bottom: 3px solid $color-primary;
  }
}

.ib-icon {
  margin-right: 10px;

  @include media-breakpoint-between($md, $xl) {
    margin-right: 0;
  }
}

.navigation-step-dropdown {
  display: block;
  min-width: 250px;
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 2px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  z-index: 101;
}

.navigation-step-item {
  color: $color-black;
  opacity: 0.5;
  transition: all 0.25s;
  align-items: center;
  padding: 10px 0;

  & .navigation-step-item-icon {
    margin-top: 5px;
    margin-right: 10px;
  }

  &:hover {
    opacity: 1;
    transition: all 0.25s;
  }

  &.active {
    opacity: 1;
    color: $color-primary;
    //font-weight: 700;
  }
}
</style>
