<template>
  <div>
    <!-- Add New Dropdown -->
    <el-dropdown
      class="add-new-dropdown"
      type="primary"
      trigger="click"
      placement="bottom"
      @visible-change="onDropdownVisibleChange"
      @command="showDialog"
    >
      <ib-button
        ref="ibButton"
        class="px-3"
        icon="el-icon-arrow-down"
        font-family="regular"
        icon-right
        icon-rotate
        :disabled="disabled"
        @click="$emit('clickButton')"
      >
        {{ $t('pages.financialPlan.addNewEdit') }}
      </ib-button>
      <el-dropdown-menu
        slot="dropdown"
        :visible-arrow="false"
      >
        <el-dropdown-item
          v-for="(option, key) in options"
          :key="key"
          class="dropdown-item"
          :command="{dialog: option.command, type: option.type, paid: option.paid}"
        >
          <simple-svg
            class="icon"
            :filepath="require(`@/assets/img/${option.iconPath}.svg`)"
            :width="'1.3em'"
            :height="'1.3em'"
          />
          {{ $tc(option.title) }}
          <template v-if="subscriptionIsFreeTrial && option.paid">
            <i class="ib-icon ib-icon-lock-2 ml-1 o-50" />
          </template>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- /Add New Dropdown -->
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AddNewDropdownButton',

  mixins: [
    MixinApiCalculations,
    MixinUserSubscription
  ],

  props: {
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dropdownVisible: false
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeMarketingElement'
    ]),
    ...mapMutations('ui', ['setDialogUpgradeState']),

    onDropdownVisibleChange (event) {
      this.dropdownVisible = event
    },

    showDialog (command) {
      if (
        this.subscriptionIsFreeTrial &&
          ['directCost', 'marketingCost', 'otherOverhead'].includes(command.type)
      ) {
        this.setDialogUpgradeState(true)

        return
      }
      EventBus.$emit('open-fp-dialog', command.dialog)
    }
  }
}
</script>

<style scoped>
.add-new-dropdown {
  margin-left: 10px;
}

.add-new-button {
  text-transform: capitalize;
  padding: 10px 20px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.add-new-button >>> span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-new-button .icon {
  transition: all ease-in-out .3s;
}

.add-new-button .active {
  transition: all ease-in-out .3s;
  transform: rotate(-180deg);
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.dropdown-item > .icon {
  margin-right: 10px;
  display: flex;
  padding: 0 5px 0 0;
}

</style>
